// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
var data = "";
export default class extends Controller {
  static targets = ["modal", "modalWrapper", "template", "cancelled"];

  initialize() {}
  connect() {
    //getting all data in form
    data = $("form").serialize();

    const modalWrapper = document.querySelector(".modal-wrapper");
    document.addEventListener("keydown", (e) => {
      if (e.key == "Escape" && !modalWrapper.classList.contains("inactive")) {
        const formChange = document.getElementById("formChange");
        var currState = formChange
          ? formChange.textContent == "changed"
          : false;
        if (
          !confirm(
            currState ? "שינויים שלא נשמרו, האם לסגור?" : "אם בטוח לצאת?"
          )
        )
          return;
        modalWrapper.classList.add("inactive");
        this.removeEventParam();

        setTimeout(() => {
          console.log(12);

          modalWrapper.style.display = "none";
          document.body.style.overflow = "auto";
        }, 200);
      }
    });
    modalWrapper.addEventListener("click", (e) => {
      const formChange = document.getElementById("formChange");
      var currState = formChange ? formChange.textContent == "changed" : false;
      if (
        e.target == modalWrapper &&
        !modalWrapper.classList.contains("inactive") &&
        confirm(currState ? "שינויים שלא נשמרו, האם לסגור?" : "אם בטוח לצאת?")
      ) {
        console.log(e);
        modalWrapper.classList.add("inactive");
        this.removeEventParam();
        setTimeout(() => {
          console.log(12);

          this.modalWrapperTarget.style.display = "none";
          document.body.style.overflow = "auto";
        }, 200);
      }
    });

    var containers = document.querySelectorAll(".availability");
    var event = new Event("refresh");

    let preUrl = "";
    const observer = new MutationObserver(function (mutations) {
      if (window.location.href !== preUrl) {
        preUrl = window.location.href;
        console.log(`URL changed from ${preUrl} to ${window.location.href}`);
        document.addEventListener("DOMContentLoaded", (event) => {
          containers.forEach((cont) => {
            cont.dispatchEvent(event);
          });
        });
      }
    });
    const config = { subtree: true, childList: true };

    // start listening to changes
    observer.observe(document, config);
  }
  add(e) {
    const modal = this.modalTarget;
    modal.style.display = "initial";
    modal.querySelector("#day").innerHTML = e.target.dataset.date;
  }
  open(e) {
    data = $("form").serialize();
    let event = "";
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    if (e.target.dataset.event) event = e.target.dataset.event;
    else if (e.target.parentElement.dataset.event)
      event = e.target.parentElement.dataset.event;
    url.searchParams.set("event", event);
    if (document.querySelector(".fullscreen"))
      document.querySelector(".fullscreen").dataset.event = event;
    window.history.pushState("", "", url);
  }
  full_screen(e) {
    let event = "new";
    if (e.currentTarget.dataset.event)
      event = e.currentTarget.dataset.event + "/edit";
    else if (e.currentTarget.parentElement.dataset.event)
      event = e.currentTarget.parentElement.dataset.event + "/edit";
    let url = "/events/" + event;
    console.log(url);
    window.open(url, "_blank").focus();
  }
  close() {
    const formChange = document.getElementById("formChange");
    var currState = formChange ? formChange.textContent == "changed" : false;
    if (
      currState &&
      !confirm(currState ? "שינויים שלא נשמרו, האם לסגור?" : "אם בטוח לצאת?")
    )
      return;
    this.removeEventParam();
    const modal = this.modalWrapperTarget;
    modal.classList.add("inactive");
    setTimeout(() => {
      console.log(12);

      modal.style.display = "none";
      document.querySelector("#calendar-modal-content").innerHTML = "";
      document.body.style.overflow = "auto";
    }, 200);
  }
  
  cancelled(e) {
    const cancelled = document.querySelectorAll(".cancelled");
    cancelled.forEach((e) => {
      e.classList.toggle("hidden");
    });
    e.target.classList.toggle("rotate");
  }
  notcancelled(e) {
    const cancelled = document.querySelectorAll(".notcancelled");
    cancelled.forEach((e) => {
      e.classList.toggle("hidden");
    });
    e.target.classList.toggle("rotate");
  }
  hover(e) {
    const event = e.target.closest(".event").dataset.event;
    document
      .querySelectorAll(`button[data-event='${event}']`)
      .forEach((btn) => {
        btn.classList.toggle("hover");
      });

    const options = {
      root: document.querySelector(".table-wrapper"),
      rootMargin: "0px",
      threshold: 0,
    };
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.boundingClientRect.right > entry.rootBounds.right) {
            entry.target.style.right = "1rem";
            entry.target.style.left = "unset";
          }
          if (entry.boundingClientRect.left < entry.rootBounds.left) {
            entry.target.style.left = "1rem";
            entry.target.style.right = "unset";
          }
          if (entry.boundingClientRect.top < entry.rootBounds.top) {
            entry.target.style.top = "1rem";
            entry.target.style.bottom = "unset";
          }
          if (entry.boundingClientRect.bottom > entry.rootBounds.bottom) {
            entry.target.style.bottom = "1rem";
            entry.target.style.top = "unset";
          }
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    const target = e.target
      .closest(".event-panel")
      .querySelector(".hover-info");
    observer.observe(target);
  }
  choose(e) {
    const date = e.target.value;
    const url = new URL(window.location.href);
    console.log(url);
    const params = new URLSearchParams(url.search);
    url.searchParams.set("start_date", date);
    if (e.target.dataset.time == "week") {
      url.searchParams.set("t", "week");
    } else {
      url.searchParams.set("t", "month");
    }
    // params.set("start_date", date)
    console.log(url);
    window.location.href = url;
  }

  toggleColor() {
    const buttons = document.querySelectorAll(".choose-color");
    buttons.forEach((e) => {
      e.classList.toggle("rand");
    });
  }
  removeEventParam() {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.delete("event");
    window.history.pushState(
      "",
      "",
      window.location.href.split("?")[0] + "?" + params
    );
  }  
}
