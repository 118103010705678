import ApplicationController from "./application_controller";
import $, { event } from "jquery";
import Select2 from "select2";
import Selectables from "./selectable.js";
import ContextMenu from "../packs/contextMenu.js";

export default class extends ApplicationController {
  static targets = [
    "addCustomer",
    "customerTemplate",
    "contacts",
    "customer",
    "startDate",
    "endDate",
    "form",
    "pricing",
    "event",
    "dontIncludeClassroomPrice",
  ];
  get formController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector("#event-form"),
      "layouts--form"
    );
  }
  connect() {
    super.connect();
    console.log("events controller");
    const now = Date.now();
    $(".select2").select2({
      theme: "bootstrap4",
    });
    $(".select2-customers").on("select2:select", (e) => {
      this.choose_customer(e);
      this.markFormAsChanged();
      if (this.hasContactsTarget) {
      }
    });
    document.addEventListener("change", (e) => {
      if (e.target.className.includes("select2-pricing")) {
        this.choose_pricing(e);
      }
      if (e.target.tagName == "INPUT" || e.target.tagName == "SELECT") {
        this.markFormAsChanged();
      }
    });
    document.addEventListener("click", (e) => {
      if (
        (e.target.className.includes("btn") &&
          e.target.className.includes("toolbar")) ||
        e.target.textContent == "X"
      ) {
        this.markFormAsChanged();
      }
    });

    $(".get-classroom-price").on("select2:select", (e) => {
      this.get_classroom_price(e);
    });

    document.addEventListener("event-updated", () => {
      this.submit();
    });
    document.addEventListener("create-customer", () => {
      this.after_create_customer();
    });

    document.querySelectorAll(".num-of-days").forEach((input, i) => {
      const tr = input.closest("tr");
      const start_time_element = tr.querySelector(".start-time");
      if (start_time_element) { 
        this.set_classroom_num_of_days({target: start_time_element})
      }
      
      const start_date_element = tr.querySelector(".start-date");
      if (start_date_element) {
        this.set_classroom_num_of_days({target: start_date_element}) 
      }
    });

    // get the sticky element
    const stickyElm = document.querySelector("#actionPanel");
    const root = document.querySelector("#calendar-modal-content");
    if (stickyElm && root) {
      const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle("isSticky", e.intersectionRatio < 1),
        { threshold: [1], root: root }
      );

      observer.observe(stickyElm);
    }

  }

  markFormAsChanged() {
    document.getElementById("formChange").textContent = "changed";
    document
      .querySelectorAll('[name="save"]')
      .forEach((btn) => btn.classList.remove("btn-secondary"));
    this.set_event_sub_totals();
  }

  choose_customer(e) {
    document.querySelectorAll(".notes_customer_id").forEach((ipt) => {
      ipt.value = e.target.value;
    });
    document.querySelector(".add-contact-block").classList.add("show");
    document.querySelectorAll(".no-customer-js").forEach((btn) => {
      btn.classList.remove("hide");
    });
    this.stimulate("Events#choose_customer", e.target.value);
    this.set_dates(e);
  }

  choose_pricing(e) {
    let pricingId = e.target.value;
    this.stimulate("Events#choose_pricing", e.target.dataset["customerId"], pricingId).then(() => {
      const dontIncludeClassroomPriceValue = $('.select2-pricing').attr('data-dont-include-classroom-price');
      $('#eventForm').data("dontIncludeClassroomPrice", JSON.parse(dontIncludeClassroomPriceValue.toLowerCase()));
      this.set_event_final_price();
    });

    this.set_dates(e);
  }

  set_dates(e) {
    const eventForm = document.querySelector("#event-form");
    if (!eventForm) return;
    
    const minValue = document.querySelector("#event_start_date").value;
    const maxValue = document.querySelector("#event_end_date").value;
    const start_date = Date.parse(minValue);
    const end_date = Date.parse(maxValue);
  
    // Traverse all inputs within #event-form
    // Specify the classes you want to target
    const relevant_inputs = eventForm.querySelectorAll(
      ".hazmana input, .shibutz-rooms input, .shibutz-classrooms input, .meals input, .shibutz-equipments input"
    );

    relevant_inputs.forEach((input) => {
      const row = input.closest("tr");
      const isStartDate = input.classList.contains("start-date");
      const isEndDate = input.classList.contains("end-date");
      if (!isStartDate && !isEndDate) return;
  
      const value = Date.parse(input.value);
  
      // Handle rows with date values outside the valid range
      if (value < start_date || value > end_date) {
        const newValue = value < start_date ? minValue : maxValue;
        const currentTable = input.closest("table");
        const duplicate = this.find_duplicate_date(currentTable, input, newValue);
        
        input.value = newValue;

        if (duplicate) {
          const removeButton = row.querySelector(
            "button[data-action='order#remove_meal']"
          );
          if (removeButton) {
            removeButton.click();
          }
        }

        if (input.dataset.action && input.dataset.action.includes('set_room_order_length')) {
          this.set_room_order_length({
            target: input,
          });
          this.set_room_order_total_price({
            target: input,
            
          });
        }

        if (input.dataset.action && input.dataset.action.includes('get_classroom_price') && row.querySelector(".start-time")) {
          this.set_classroom_num_of_days({
            target: row.querySelector(".start-time"),
          });

          this.set_classroom_total_price({
            target: input,
          });
        };
        
        if (input.dataset.action && input.dataset.action.includes('set_equipment_length') && row.querySelector(".start-date")) {
          this.set_equipment_length({
            target: row.querySelector(".start-date"),
          });

          this.set_equipment_total_price({
            target: input,
          });
        };
        if (input.dataset.action && input.dataset.action.includes('set_meal_unit_price')) {
          this.set_meal_total_price({
            target: input,
          });
        };
      }
    });

    if (start_date) {
      const elems = document.querySelectorAll(".set_start_date");
      elems.forEach((el) =>
        el.setAttribute(
          "min", minValue
        )
      );
    }
    if (end_date) {
      const elems = document.querySelectorAll(".set_end_date");
      elems.forEach((el) =>
        el.setAttribute("max", maxValue)
      );
    }

    if (!start_date || !end_date || end_date < start_date) {
      document.querySelector("#event_end_date").value = "";
      document.querySelector(".dates-error").classList.add("show");
      return;
    } else {
      document.querySelector(".dates-error").classList.remove("show");
    }

    this.set_event_final_price({
      target: relevant_inputs[0],
    });
  }
  
  // Helper: Find duplicates within a specific section (e.g., meals)
  find_duplicate_date(currentTable, currentInput, newValue) {
    const currentTableIsMeals = currentInput.closest('.meals'); // Run duplicates check only on meals table
    if (!currentTableIsMeals) return; // Skip if not under the specific class

    // Find another input within the same table with the new value
    return Array.from(currentTable.querySelectorAll("input[type='date']")).find(
      (input) => input !== currentInput && input.value === newValue
    );
  }

  set_new_dates(e) {
    const start_date = document.querySelector("#event_start_date").value;
    const end_date = document.querySelector("#event_end_date").value;
    const new_start_date = document.querySelector(
      "#event_new_start_date"
    ).value;

    const new_end_date = this.calc_diff_from_start(
      start_date,
      new_start_date,
      end_date
    );

    document.querySelector("#event_start_date").value =
      this.date_to_string(new_start_date);
    document.querySelector("#event_end_date").value =
      this.date_to_string(new_end_date);

    const start_elems = document.querySelectorAll(".start-date.set_start_date");
    start_elems.forEach(
      (el) =>
        (el.value = this.date_to_string(
          this.calc_diff_from_start(start_date, new_start_date, el.value)
        ))
    );

    const end_elems = document.querySelectorAll(".end-date.set_end_date");
    end_elems.forEach(
      (el) =>
        (el.value = this.date_to_string(
          this.calc_diff_from_start(start_date, new_start_date, el.value)
        ))
    );

    this.set_dates(e);
    document.getElementById("new_date_wrapper").classList.toggle("hide");
  }

  calc_diff_from_start(oldStartDate, newStartDate, current) {
    const old_date = Date.parse(oldStartDate);
    const new_date = Date.parse(newStartDate);

    const diffDays = Math.floor((new_date - old_date) / 86400000);

    const old_current = new Date(current);
    const new_current = new Date(current);
    new_current.setDate(old_current.getDate() + diffDays);
    return new_current.toString();
  }
  date_to_string(date) {
    return new Date(date).toISOString().slice(0, 10);
  }
  add_customer(e) {
    const modal = document.querySelector(".customer-modal-wrapper");
    modal.style.display = "initial";
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      modal.classList.remove("inactive");
    }, 200);
    $(".select2").select2({
      theme: "bootstrap4",
    });
  }

  close_customer() {
    console.log("close");
    const modal = document.querySelector(".customer-modal-wrapper");
    modal.classList.add("inactive");
    setTimeout(() => {
      modal.style.display = "none";
      document.body.style.overflow = "auto";
    }, 200);
  }

  after_create_customer(e) {
    this.close_customer();
    this.stimulate("Events#after_create_customer");
    $(".select2").select2({
      theme: "bootstrap4",
    });
  }

  remove_customer(e) {
    let wrapper = e.target.closest(".nested-customers");

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
  }

  set_meal_total_price(e) {
    const tr = e.target.closest("tr");
    const participant_num = tr.querySelector(".participant_num").value;
    const unit_price = tr.querySelector(".unit-price-input").value;
    if (participant_num != "" && unit_price != "") {
      tr.querySelector(".total-price-input").value =
        parseInt(participant_num) * parseFloat(unit_price);
      tr.querySelector(".total-price-input").setAttribute(
        "value",
        parseInt(participant_num) * parseFloat(unit_price)
      );
    }
    this.set_event_final_price();
  }

  set_meal_unit_price(e, isEvent = true) {
    const isParticipant = isEvent
      ? e.target.dataset.participantNum == "true"
      : e.dataset.participantNum == "true";
    const tr = isEvent ? e.target.closest("tr") : e.closest("tr");
    const date = tr.querySelector("[type=date]").value;
    const kind = tr.querySelector("select:first-of-type").value;
    const unit_price_id = tr.querySelector(".unit-price-input").id;
    const unit_price_td = tr
      .querySelector(".unit-price-input")
      .closest("td").id;
    const unit_price_name = tr.querySelector(".unit-price-input").name;
    const participant_num = tr.querySelector(".participant_num").value;
    const total_price_id = tr.querySelector(".total-price-input").id;
    const total_price_td = tr
      .querySelector(".total-price-input")
      .closest("td").id;
    const total_price_name = tr.querySelector(".total-price-input").name;
    if (date != "" && kind != "") {
      this.stimulate(
        "Events#set_meal_unit_price",
        date,
        kind,
        unit_price_id,
        unit_price_td,
        unit_price_name,
        total_price_id,
        total_price_td,
        total_price_name,
        participant_num,
        isParticipant
      );
    }
  }

  set_length(e) {
    const start_date = Date.parse(this.startDateTarget.value);
    const end_date = Date.parse(this.endDateTarget.value);
    const diffDays =
      Math.ceil((end_date - start_date) / (1000 * 60 * 60 * 24)) + 1;
    const form = e.target.closest(".form-inputs");
    form.querySelector(".event-length").value = diffDays;
  }

  submit(e) {
    this.stimulate("Events#submit");
  }

  set_taken(e) {
    let tr;
    if (e.target) {
      tr = e.target.closest("tr");
    }
    if (typeof e === "number") {
      tr = document.querySelector(`tr[data-index='${e}']`);
    }
    if (e.className) {
      tr = e;
    }
    const taken_rooms_list_td = tr
      .querySelector(".taken_rooms_list")
      .closest("td").id;
    const start_date = tr.querySelector(".start-date").value;
    const end_date = tr.querySelector(".end-date").value;
    const eventID = tr.querySelector(".taken_rooms_list").closest("td").dataset[
      "id"
    ];
    if (this.isActionCableConnectionOpen()) {
      this.stimulate(
        "Events#setTaken",
        start_date,
        end_date,
        taken_rooms_list_td,
        eventID
      ).catch((err) => console.log(err));
    }
  }

  set_room_order_price(e) {
    const customer_id = this.customerTarget.value;
    const pricing_id = this.pricingTarget.value;
    const tr = e.target.closest("tr");
    const total_price_id = tr.querySelector(".total-price-input").id;
    const total_price_td = tr
      .querySelector(".total-price-input")
      .closest("td").id;
    const total_price_name = tr.querySelector(".total-price-input").name;
    const participant_price_id = tr.querySelector(
      ".participant-price-input"
    ).id;
    const participant_price_td = tr
      .querySelector(".participant-price-input")
      .closest("td").id;
    const participant_price_name = tr.querySelector(
      ".participant-price-input"
    ).name;
    const start_date = tr.querySelector(".start-date").value;
    const end_date = tr.querySelector(".end-date").value;
    const num_in_room = tr.querySelector(".num-in-room").value;
    const sheets = tr.querySelector(".sheets").checked;
    const towels = tr.querySelector(".towels").checked;
    const participant_num = tr.querySelector(".participant-num").value;
    const participant_called = e.target.classList.contains(
      "participant-price-input"
    )
      ? true
      : false;
    if (
      customer_id !== "" &&
      num_in_room !== "" &&
      start_date !== "" &&
      end_date !== ""
    ) {
      this.stimulate(
        "Events#set_room_order_price",
        customer_id,
        start_date,
        end_date,
        num_in_room,
        sheets,
        towels,
        participant_num,
        total_price_id,
        total_price_td,
        total_price_name,
        participant_price_id,
        participant_price_td,
        participant_price_name,
        pricing_id
      ).then(this.formController.updateInputSize());
    }
  }

  add_sheets_and_towels_price(e) {
    const pricing_id = this.pricingTarget.value;
    const customer_id = this.customerTarget.value;
    const tr = e.target.closest("tr");
    const total_price = tr.querySelector(".total-price-input").value;
    const participant_num = tr.querySelector(".participant-num").value;
    const total_price_id = tr.querySelector(".total-price-input").id;
    const total_price_td = tr
      .querySelector(".total-price-input")
      .closest("td").id;
    const total_price_name = tr.querySelector(".total-price-input").name;
    const participant_price = tr.querySelector(
      ".participant-price-input"
    ).value;
    const participant_price_id = tr.querySelector(
      ".participant-price-input"
    ).id;
    const participant_price_td = tr
      .querySelector(".participant-price-input")
      .closest("td").id;
    const participant_price_name = tr.querySelector(
      ".participant-price-input"
    ).name;
    const sheets = tr.querySelector(".sheets").checked;
    const towels = tr.querySelector(".towels").checked;
    let changed;
    if (e.target.classList.contains("towels")) {
      changed = "towels";
    } else if (e.target.classList.contains("sheets")) {
      changed = "sheets";
    }
    if (
      customer_id !== "" &&
      sheets !== "" &&
      towels !== "" &&
      participant_price !== ""
    ) {
      this.stimulate(
        "Events#add_sheets_and_towels_price",
        customer_id,
        sheets,
        towels,
        changed,
        participant_price,
        total_price,
        participant_num,
        total_price_id,
        total_price_td,
        total_price_name,
        participant_price_id,
        participant_price_td,
        participant_price_name,
        pricing_id
      ).then(() => this.set_event_final_price());
    }
  }

  set_room_order_total_price(e) {
    const tr = e.target.closest("tr");
    const total_price = tr.querySelector(".total-price-input");
    const participant_num = parseInt(
      tr.querySelector(".participant-num").value
    );
    const participant_price = parseFloat(
      tr.querySelector(".participant-price-input").value
    );
    const night_amount = parseInt(
      tr.querySelector(".night-amount-input").value
    );
    total_price.value =
      parseFloat(participant_price) *
      parseFloat(participant_num) *
      parseFloat(night_amount);
  }

  set_room_order_length(e) {
    const tr = e.target.closest("tr");
    const start_date = Date.parse(tr.querySelector(".start-date").value);
    const end_date = Date.parse(tr.querySelector(".end-date").value);
    const diffDays = Math.ceil((end_date - start_date) / (1000 * 60 * 60 * 24));
    tr.querySelector(".night-amount").innerHTML = diffDays;
    tr.querySelector(".night-amount-input").value = diffDays;
  }

  set_room_amount(e) {
    const tr = e.target.closest("tr");
    const participant_num = tr.querySelector(".participant-num").value;
    const beds_per_night = tr.querySelector(".num-in-room").value;
    const rooms = Math.ceil(participant_num / beds_per_night);
    tr.querySelector(".room-amount").innerHTML = rooms;
    tr.querySelector(".room-amount-input").value = rooms;
  }

  set_classroom_length(e) {
    const tr = e.target.closest("tr");
    const start_time_element = tr.querySelector(".start-time");
    this.set_classroom_num_of_days({ target: start_time_element})
  }

  set_equipment_length(e) {
    const tr = e.target.closest("tr");
    const start_date_element = tr.querySelector(".start-date");
    this.set_classroom_num_of_days({target: start_date_element})
  }

  get_equipment_price(e) {
    this.get_classroom_price(e);
  }

  set_equipment_total_price(e) {
    this.set_classroom_total_price(e);
  }
  
  get_classroom_price(e) {
    const pricing_id = this.pricingTarget.value;
    const tr = e.target.closest("tr");
    const space_id =
    tr.querySelector(".get-classroom-price")?.value ||
    tr.querySelector(".get-space-id")?.value ||
    tr.querySelector(".get-equipment-price")?.value;
    const num_of_days = tr.querySelector(".num-of-days").value;
    const total_price_id = tr.querySelector(".total-price-input").id;
    const total_price_td = tr
      .querySelector(".total-price-input")
      .closest("td").id;
    const total_price_name = tr.querySelector(".total-price-input").name;
    const unit_price_id = tr.querySelector(".unit-price-input").id;
    const unit_price_td = tr
      .querySelector(".unit-price-input")
      .closest("td").id;
    const unit_price_name = tr.querySelector(".unit-price-input").name;
    if (pricing_id !== "" && space_id !== "" && num_of_days !== "") {
      this.stimulate(
        "Events#get_classroom_price",
        space_id,
        pricing_id,
        num_of_days,
        total_price_id,
        total_price_td,
        total_price_name,
        unit_price_id,
        unit_price_td,
        unit_price_name
      );
    }
  }


  set_classroom_total_price(e) {
    const tr = e.target.closest("tr");
    const unit_price = parseFloat(tr.querySelector(".unit-price-input").value) || 0;
    const half_day_price = parseFloat(tr.querySelector(".unit-price-input").dataset.halfDayPrice) || 0;
    const diffDays = parseFloat(tr.querySelector(".num-of-days").value) || 0;
    
    const fullDays = Math.floor(diffDays); // Extract the integer part (full days)
    const hasHalfDay = diffDays % 1 === 0.5; // Check if there’s a half-day
    
    let total_price = fullDays * unit_price; // Price for full days
    if (hasHalfDay) {
      total_price += half_day_price; // Add half-day price if applicable
    }

    tr.querySelector(".total-price-input").value = total_price.toFixed(2);
    tr.querySelector(".total-price-input").setAttribute("value", total_price.toFixed(2));
  }

  set_classroom_num_of_days(e) {
    const this_element = e.target || e
    const timeStart = this_element.value;
    const tr = this_element.closest("tr");
    const timeToCheckDate = new Date(`1970-01-01T${timeStart}:00Z`);
    const referenceTimeDate = new Date(`1970-01-01T14:59:00Z`);
    let firstDayEqual = 1;
    const input = tr.querySelector(".num-of-days");
    if (timeToCheckDate > referenceTimeDate) {
      firstDayEqual = 0.5
    }
    const start_date = Date.parse(tr.querySelector(".start-date").value);
    const end_date = Date.parse(tr.querySelector(".end-date").value);
    const diffDays = Math.ceil(
      (end_date - start_date) / (1000 * 60 * 60 * 24) 
    )+ firstDayEqual;
    
    
    if( this_element.classList.contains('classroom_inputs') && !this_element.classList.contains('start-time') )
    {
      return
    }
    
    if(!isNaN(diffDays))
    {
      input.value = diffDays;
      input.setAttribute("value", diffDays);
    }
  }

  finalizeReflex(element, reflex, noop, reflexId) {
    if (
      reflex == "Events#set_room_order_price" ||
      reflex == "Events#get_classroom_price" ||
      reflex == "Events#set_meal_unit_price"
    ) {
      this.set_event_final_price();
    }
    $("#event_contact_ids").select2();
  }

  set_discount_price(e) {
    let discount_wrapper = e.target.closest(".discount-calc");
    const discount_num = discount_wrapper.querySelector(".discount-input");
    const discount_prec = discount_wrapper.querySelector(
      ".discount-prec-input"
    );
    const sum = discount_wrapper.querySelector(".js-subSum");

    if (discount_num == e.target) {
      discount_prec.value =
        Math.round(
          (parseFloat(discount_num.value) / parseFloat(sum.textContent)) *
            100 *
            100
        ) / 100;
    }
    if (discount_prec == e.target) {
      discount_num.value =
        Math.round(
          (parseFloat(discount_prec.value) / 100) *
            parseFloat(sum.textContent) *
            100
        ) / 100;
    }
  }

  set_event_final_price() {
    let inputs = document.querySelectorAll(".total-price-input");
    let discounts = document.querySelectorAll(".discount-input");
    let totalPrice = 0;
    let priceBeforeDiscount = 0;
    let additional_charges = document.querySelector(
      "#event_additional_charges"
    ).value;
    let discount = document.querySelector("#event_discount").value;
    let eventTotalInput = document.querySelector("#event_final_price");
    const oldTotal = eventTotalInput.value;
    const pricingDontIncludeClassroomPrice = $('#eventForm').data("dontIncludeClassroomPrice");

    inputs.forEach(function (input) {      
      if (input.value != "" && input.closest("tr").style.display != "none") {
        
        if ( (pricingDontIncludeClassroomPrice == true && input.classList.contains("classroom_inputs")))
        {
          totalPrice += 0;
        }
        else
        {
          totalPrice += parseFloat(input.value);
        }
      }
    });
    discounts.forEach(function (input) {
      if (input.value != "") {
        totalPrice -= parseFloat(input.value);
      }
    });
    if (additional_charges != "") {
      additional_charges = parseFloat(additional_charges);
      totalPrice = totalPrice + additional_charges;
    }
    priceBeforeDiscount = totalPrice;
    if (discount != "") {
      discount = parseFloat(discount);
      totalPrice = totalPrice - (totalPrice * discount) / 100;
    }
    eventTotalInput.value = totalPrice;
    const event = new Event("change");
    eventTotalInput.dispatchEvent(event);
    eventTotalInput.classList.remove("more");
    eventTotalInput.classList.remove("less");
    if (oldTotal > totalPrice) {
      eventTotalInput.classList.add("less");
    }
    if (oldTotal < totalPrice) {
      eventTotalInput.classList.add("more");
    }
    eventTotalInput.classList.remove("change-total-input");
    setTimeout(function () {
      eventTotalInput.classList.add("change-total-input");
    }, 1);
    document.querySelector("#price_before_discount").value =
      priceBeforeDiscount;

    document.querySelector("#discount_value").value =
      (priceBeforeDiscount * discount) / 100;
    console.log(`set_event_final_price to: ${totalPrice}`);
    this.set_event_sub_totals();
  }

  set_event_sub_totals() {
    let inputs = document.querySelectorAll(".total-price-input");
    let total_meals = 0;
    let total_rooms = 0;
    let total_classrooms = 0;
    let participant_num = 0;
    let num_in_room = 0;
    let room_orders = 0;
    let chunks = 0;
    let notes = 0;

    inputs.forEach(function (input) {
      if (input.className.includes(" meals")) {
        total_meals += parseFloat(input.value) || 0;
      }
      if (input.className.includes(" rooms")) {
        total_rooms += parseFloat(input.value) || 0;
      }
      if (input.className.includes(" classrooms")) {
        total_classrooms += parseFloat(input.value) || 0;
      }
    });

    document.querySelectorAll(".notes.hidden").forEach((el) => (notes += 1));
    document.querySelectorAll(".chunks.numeric").forEach((el) => (chunks += 1));

    document
      .querySelectorAll(".room_orders")
      .forEach((el) => (room_orders += parseInt(el.value) || 0));
    document
      .querySelectorAll(".participant-num.numeric")
      .forEach((el) => (participant_num += parseInt(el.value) || 0));
    document
      .querySelectorAll(".num_in_room.numeric")
      .forEach((el) => (num_in_room += parseInt(el.value) || 0));

    if (chunks > room_orders) {
      document
        .querySelector(".js-chunks_warning.icon")
        .classList.remove("hide");
      document
        .querySelector(".js-chunks_warning.panel")
        .classList.add("warning");
    } else {
      document.querySelector(".js-chunks_warning.icon").classList.add("hide");
      document
        .querySelector(".js-chunks_warning.panel")
        .classList.remove("warning");
    }

    document.querySelector(".js-subSum.js-num_in_room").textContent =
      num_in_room || 0;
    document.querySelector(".js-subSum.js-participant-num").textContent =
      participant_num || 0;
    document.querySelector(".js-subSum.js-chunks").textContent = chunks || 0;
    document.querySelector(".js-subSum.js-room_orders").textContent =
      room_orders || 0;
    document.querySelector(".js-subSum.meals").textContent =
      (total_meals || 0) + " ₪";
    document.querySelector(".js-subSum.notes").textContent = notes || 0;

    document
      .querySelectorAll(".js-subSum.rooms")
      .forEach((el) => (el.textContent = (total_rooms || 0) + " ₪"));
    document
      .querySelectorAll(".js-subSum.classrooms")
      .forEach((el) => (el.textContent = (total_classrooms || 0) + " ₪"));
    document.querySelectorAll(".discount-calc").forEach((el) => {
      this.set_discount_price({
        target: el.querySelector(".discount-prec-input"),
      });
    });
  }

  toggleHide() {
    const table = document.querySelector(".meal-table");
    const classes = table.classList;
    classes.contains("hideMore")
      ? table.classList.remove("hideMore")
      : table.classList.add("hideMore");
  }

  change_status(e) {
    const target = e.target;
    let canChangeStatus = true;
    if (e.target.value == "zikuy") {
      canChangeStatus = confirm("are you sure");
    }
    if (canChangeStatus) {
      const status =
        target.value + "-bg custom-bg btn btn-secondary select-status";
      target.className = status;
      this.stimulate("Events#event_status", target.dataset.event, target.value)
        .then((rr) => {
          if (target.value == "ordered" || target.value == "payed") {
            confetti();
          }
          target.dataset.old = target.value;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      target.value = target.dataset.old;
    }
  }

  toggleWizard(e) {
    const w = document.querySelector(".wizard");
    if (w.className.includes("show")) {
      w.classList.remove("show");
    } else {
      const start_date = Date.parse(
        document.querySelector("#event_start_date").value
      );
      const end_date = Date.parse(
        document.querySelector("#event_end_date").value
      );
      if (start_date && end_date) {
        const diffDays =
          Math.ceil((end_date - start_date) / (1000 * 60 * 60 * 24)) + 1;
        const tbody = document.querySelector(".wizard-tbody");
        tbody.innerHTML = "";
        const meals = [];
        const mealsTh = document.querySelectorAll(".wizard-head");
        mealsTh.forEach((el) => meals.push(el.getAttribute("data-mealtype")));
        for (let index = 0; index < diffDays; index++) {
          const date = new Date(start_date);
          date.setDate(date.getDate() + index);
          const d = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
          const m =
            date.getMonth() + 1 > 9
              ? date.getMonth() + 1
              : "0" + (date.getMonth() + 1);
          const dateStr = date.getFullYear() + "-" + m + "-" + d;
          const tr = document.createElement("tr");
          const tdDate = document.createElement("td");
          tdDate.textContent = date.toLocaleDateString();
          tr.appendChild(tdDate);
          const td = document.createElement("td");
          td.innerHTML = `<input type="checkbox" class="row-column" data-action="input->order#setMealRowColumn" data-mealdate="${dateStr}">`;
          tr.appendChild(td);
          meals.forEach((meal) => {
            const td = document.createElement("td");
            // const input = document.createElement('input')
            td.innerHTML = `<input type="checkbox" class="one-meal" data-mealtype="${meal}" data-mealdate="${dateStr}"></input>`;
            tr.appendChild(td);
          });
          tbody.appendChild(tr);
        }

        w.classList.remove("no-date");
      } else {
        w.classList.add("no-date");
      }
      document.getElementById("wizard-participant").value =
        document.querySelector("#event_participant_num").value;
      w.classList.add("show");
      this.toggleTab(e);
    }
  }

  toggleTab(e) {
    const tabs = document.querySelectorAll(".wizard-tab");
    const btns = document.querySelectorAll(".wizard-btn");
    const type = e.target.dataset.type;
    if (type) {
      tabs.forEach((tab) => {
        if (tab.getAttribute("data-type") === type) {
          tab.style.display = "block";
        } else {
          tab.style.display = "none";
        }
      });
      btns.forEach((btn) => {
        if (btn.getAttribute("data-type") === type) {
          btn.classList.add("active");
        } else {
          btn.classList.remove("active");
        }
      });
    }
  }

  showExportMenu(e) {
    e.preventDefault();
    document.querySelector(".export-menu").classList.toggle("open-menu");
  }

  open_spaces(e) {
    const start_date = document.querySelector("#event_start_date").value;
    const end_date = document.querySelector("#event_end_date").value;
    const event_id = e.target.dataset.event_id;
    const kind = e.target.dataset.kind;
    document.querySelector("#shibutz_modal").classList.toggle("hide");
    const that = this;
    this.stimulate(
      "room_order#spaces_modal",
      event_id,
      start_date,
      end_date,
      kind
    ).then((rr) => {
      const selsctableTable = new Selectables({
        elements: "td",
        skipElements: ["a", "button", "span"],
        selectedClass: "selected-td",
        zone: "#space-table tbody",
        moreUsing: "ctrlKey",
        stop: function (e) {},
      });

      const contextMenu = new ContextMenu(that, {});
    });
  }

  close_spaces(e) {
    document.querySelector("#shibutz_modal").classList.toggle("hide");
  }
}
